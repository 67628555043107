<template>
  <div class="">
    <!-- Header -->
    <div class="header py-5">
      <div class="container">
        <div class="header-body text-center mb-7">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6 col-md-8 px-5">
              <h1 class="text-dark">
                <img :src="image" height="100px"/>
              </h1>
              <p class="text-lead text-dark">Cadastre uma nova senha de acesso.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Page content -->
    <div class="container mt--8 pb-5">
      <div class="row justify-content-center">
        <div class="col-lg-5 col-md-7">
          <div class="card bg-secondary border-0 mb-0">
            <div class="card-body px-lg-5 py-lg-5">
              <div class="text-center text-muted mb-4">
              </div>
              <validation-observer v-slot="{handleSubmit}" ref="formValidator">
                <form role="form" @submit.prevent="recovery">
                  <base-input alternative
                              class="mb-3"
                              name="password"
                              :rules="{required: true, min: 4}"
                              prepend-icon="ni ni-lock-circle-open"
                              type="password"
                              placeholder="Nova senha"
                              v-model="password">
                  </base-input>

                  <base-input alternative
                              class="mb-3"
                              name="confirmPassword"
                              :rules="{required: true, min: 4}"
                              prepend-icon="ni ni-lock-circle-open"
                              type="password"
                              placeholder="Confirme a Senha"
                              v-model="confirmPassword">
                  </base-input>

                  <vue-recaptcha
                    ref="recaptcha"
                    :loadRecaptchaScript="true"
                    badge="bottomleft"
                    size="invisible"
                    @verify="onCaptchaVerified"
                    @expired="onCaptchaExpired"
                    sitekey="6LcL_7kZAAAAAKvH8isNe9m69iPxtOKkTVovNcrX">
                  </vue-recaptcha>
                  <div class="text-center">
                    <base-button type="primary" native-type="submit" class="my-4">Confirmar nova senha</base-button>
                  </div>
                </form>
              </validation-observer>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-6">
              <router-link to="/login" class="text-primary"><small>Ir para o login</small></router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import VueRecaptcha from 'vue-recaptcha'
  import {failWs, postWs} from '../../ws.service'
  import image from "../../assets/logo.png"

  export default {
    components: {
      VueRecaptcha
    },
    data() {
      return {
        image: image,
        showSubmit: true,
        key: '',
        password: '',
        confirmPassword: '',
        form: {
          username: '',
          password: '',
          rememberMe: false
        }
      };
    },
    created() {
      this.key = this.$route.params.key
    },
    mounted() {
      if (localStorage.getItem('isAuth')) {
        this.$router.push('/dashboard')
      }
    },
    methods: {
      recovery() {
          postWs("/auth/password-recovery", false, null,
            {hash: this.key, newPassword: this.password},
            this.successRecovery, this.failRecovery)
      },
      successRecovery() {
        this.$swal({
          title: "Senha Alterada",
          text: "Você já pode acessar sua conta com a nova senha.",
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-success btn-fill'
          },
          closeOnClickModal: false,
          icon: "success"
        })
        this.$router.push('/login')
      },
      failRecovery(error) {
        if (error.response.status == 404) {
          this.$swal({
            title: "O prazo expirou!",
            text: "O tempo para recuperação de senha usando este link expirou. Favor re-solicitar a recuperação de senha.",
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-warning btn-fill'
            },
            closeOnClickModal: false,
            icon: "warning"
          })
          this.$router.push('/forgot')
          return
        }
        failWs(error)
      },
      getError(fieldName) {
        return this.errors.first(fieldName)
      }
    },
    beforeDestroy() {
      this.closeMenu()
    }
  };
</script>
